export const New = () => {
  return (
    <div className="new">
      <p className="new__title">What's news?</p>
      <p className="new__description">
        The Bonuses and Promotions section has been updated
      </p>
      <p className="new__description">
        Added the location of the nearest branch, ATM or terminal on the map.
      </p>
      <p className="new__description">
        Added support for Face ID and Touch ID.
      </p>
      <p className="new__description">
        Fixed a bug with push notifications that occurred for some Android 10
        users.
      </p>
      <p className="new__description">
        Fixed an issue where the text in Settings was too small for Android
        users.
      </p>
    </div>
  );
};
